import * as React from 'react'

import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import { requestLogin } from '../../core/store/auth'
import { Typography, Button, Link } from '@material-ui/core'
import FacebookIcon from '@material-ui/icons/Facebook'
import { useHistory } from 'react-router-dom'
import { ApplicationState } from '../../core/store'
import { useDispatch, useSelector } from 'react-redux'

interface LoginPageProps {
  classes: any
  location?: any
}

const LoginPage: React.FC<LoginPageProps> = (props) => {
  const { location, classes } = props
  const dispatch = useDispatch()
  const history = useHistory()
  const authState = useSelector((state: ApplicationState) => state.auth)

  const findLocation = () => {
    return location?.state?.currentLocation ? location.state.currentLocation : '/'
  }

  const LoginForm = () => {
    return (
      <div className={classes.heroContent}>
        <Typography variant="h4" align="center" color="textPrimary" gutterBottom>
          Du må logge inn
        </Typography>
        <div className={classes.heroButtons}>
          <Button
            className={classes.fbButton}
            onClick={() => dispatch(requestLogin(findLocation()))}
            size="large"
            startIcon={<FacebookIcon />}
          >
            Logg inn med Facebook
          </Button>
        </div>
        <Typography variant="body1" color="textSecondary" paragraph>
          VIKTIG: Vi krever at du registrerer en konto hos oss for å kunne være med på en Kwiz. Dette skjer automatisk
          idet du logger inn via din Facebook-konto første gang. Grunnen til at vi krever dette er for at vi skal kunne
          vite hvem du er og forhindre misbruk av tjenestene vi tilbyr. Vi samler kun inn ditt navn, e-post og
          profilbilde. I tilegg vil vi kunne be om ditt telefonnummer inne i konkurranser der arrangør av konkurransen
          vil trenge å komme i kontakt med deg for premieutdeling. Du kan lese mer om hvordan vi håndterer dine
          personopplysninger i vår <Link onClick={() => history.push('/personvern')}>Personvernærklæring</Link>. I
          tillegg ber vi deg lese våre{' '}
          <Link onClick={() => history.push('/vilkaar')}>vilkår for bruk av dette nettstedet</Link>.
        </Typography>
      </div>
    )
  }

  const LoginSpinner = () => {
    return (
      <Typography variant="h4" align="center" gutterBottom>
        Logger inn...
      </Typography>
    )
  }

  const Error = () => {
    return (
      <Typography variant="h4" align="center" gutterBottom>
        Det oppstod en feil under innlogging...
      </Typography>
    )
  }

  const LoggedIn = () => {
    return (
      <Typography variant="h4" align="center" gutterBottom>
        Du er allerede logget inn :)
      </Typography>
    )
  }

  const CheckLoggedIn = () => {
    return (
      <Typography variant="h4" align="center" gutterBottom>
        Sjekker innlogging
      </Typography>
    )
  }

  const render = () => {
    if (authState.state === 'init') {
      return CheckLoggedIn()
    } else if (authState.state === 'logging_in') {
      return LoginSpinner()
    } else if (authState.state === 'login_error') {
      return Error()
    } else if (authState.state === 'logged_in') {
      return LoggedIn()
    } else {
      return LoginForm()
    }
  }

  return <div className={classes.root}>{render()}</div>
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      height: '100%',
      paddingBottom: '50px',
    },
    heroContent: {
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      borderRadius: '10px',
      padding: '20px',
    },
    heroButtons: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      textAlign: 'center',
    },
    fbButton: {
      color: '#ffffff',
      backgroundColor: '#3966B8',
      '&:hover': {
        background: '#3966B8',
      },
    },
    container: {
      fontFamily: "'Gloria Hallelujah', cursive",
    },
    teaser: {},
  })

export default withStyles(styles, { withTheme: true })(LoginPage)
