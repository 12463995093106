import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import * as React from 'react'
import JoinBox from './JoinBox'

interface IndexPageProps {
  classes: any
}

const IndexPage: React.FC<IndexPageProps> = (props: IndexPageProps) => {
  const { classes } = props

  return (
    <div className={classes.root}>
      <JoinBox />
    </div>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    heroButtons: {
      marginTop: theme.spacing(4),
    },
    container: {
      fontFamily: "'Gloria Hallelujah', cursive",
    },
    teaser: {},
    frontImage: {
      width: '100%',
      borderRadius: '10px',
    },
  })

export default withStyles(styles, { withTheme: true })(IndexPage)
