import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import MenuIcon from '@material-ui/icons/Menu'
import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { useHistory } from 'react-router-dom'

import { ApplicationState } from '../../core/store'
import { requestLogout } from '../../core/store/auth'
import logo from '../../resources/logo.png'
import Toolbar from './Toolbar'

interface AppAppBarProps {
  classes: any
}

const AppAppBar: React.FC<AppAppBarProps> = (props) => {
  const { classes } = props
  const auth = useSelector((state: ApplicationState) => state.firebase!.auth)
  const dispatch = useDispatch()
  const history = useHistory()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    setAnchorEl(null)
    dispatch(requestLogout(''))
  }

  const handleLogin = () => {
    setAnchorEl(null)
    history.push('/login')
  }

  const loggedIn = (): boolean => {
    return auth !== undefined && isLoaded(auth) && !isEmpty(auth)
  }

  const menuItems = () => {
    const items = []

    if (!loggedIn()) {
      items.push(
        <MenuItem key="menu1" onClick={handleLogin}>
          Logg inn
        </MenuItem>
      )
    } else {
      items.push(
        <MenuItem key="menu1" onClick={handleClose}>
          Profil
        </MenuItem>
      )
      items.push(
        <MenuItem key="menu2" onClick={handleLogout}>
          Logg ut
        </MenuItem>
      )
    }

    return items
  }
  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
        <div onClick={() => history.push('/')}>
          <img src={logo} className={classes.appLogo} alt=""></img>
        </div>
        <div>
          <IconButton onClick={handleMenu} color="inherit">
            <MenuIcon className={classes.burger} />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClose}
          >
            {menuItems()}
          </Menu>
        </div>
      </Toolbar>
    </React.Fragment>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      zIndex: theme.zIndex.drawer + 1,
    },
    title: {
      fontSize: 24,
    },

    toolbar: {
      justifyContent: 'space-between',
      backgroundColor: 'transparent',
    },
    appLogo: {
      height: '25px',
      cursor: 'pointer',
    },
    left: {
      flex: 1,
    },
    leftLinkActive: {
      color: theme.palette.common.white,
    },
    right: {
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    rightLink: {
      fontSize: 16,
      color: theme.palette.common.white,
      marginLeft: theme.spacing(3),
    },
    linkSecondary: {
      color: theme.palette.secondary.main,
    },
    burger: {
      color: '#fff',
    },
  })

export default withStyles(styles, { withTheme: true })(AppAppBar)
