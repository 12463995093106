import * as React from 'react'
import { useSelector } from 'react-redux'
import { isEmpty, isLoaded } from 'react-redux-firebase'
import { Route, Switch } from 'react-router-dom'

import { ApplicationState } from '../core/store'
import LoginPage from './main/LoginPage'
import IndexPage from './main/IndexPage'
import { Redirect } from 'react-router-dom'
import GameController from './game/GameController'
import NoMatch from './main/NoMatch'
import GameStarter from './game/GameStarter'
import PrivacyPolicy from './main/PrivacyPolicy'
import TermsOfUse from './main/TermsOfUse'
import Help from './main/Help'

const PrivateRoute = (props: any) => {
  const auth = useSelector((state: ApplicationState) => state.firebase!.auth)
  return auth !== undefined && isLoaded(auth) && !isEmpty(auth) ? (
    <Route {...props} />
  ) : (
    <Redirect to={{ pathname: '/login/', state: { currentLocation: props.computedMatch.url } }} />
  )
}

const Routes: React.SFC = () => (
  <Switch>
    <Route exact path="/" component={IndexPage} />
    <Route exact path="/login" component={LoginPage} />
    <Route exact path="/hjelp" component={Help} />
    <Route exact path="/personvern" component={PrivacyPolicy} />
    <Route exact path="/vilkaar" component={TermsOfUse} />
    <PrivateRoute exact path="/spill/:id" component={GameController} />
    <PrivateRoute exact path="/spill/:id/start" component={GameStarter} />
    <Route component={NoMatch} />
  </Switch>
)

export default Routes
