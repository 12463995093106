import * as React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from './PrivacyPolicy'
import { Paper } from '@material-ui/core'

interface TermsOfUserProps {
  classes: any
}

const TermOfUse: React.FC<TermsOfUserProps> = (props) => {
  const { classes } = props

  return (
    <Paper>
      <div className={classes.root}>
        <h1>Brukervilkår</h1>

        <h2>Innledning</h2>
        <p>
          Vi i Kwiz.no ønsker å gi deg en trygg og morsom opplevelse. Våre Brukervilkår skal sikre at alle forstår
          hvilke regler som gjelder. Ved å besøke og/eller bruke vår nettside (kwiz.no), bekrefter du at du godtar at du
          blir bundet av Brikervilkårene, og at du vil følge dem. Husk at det er du som er ansvarlig for måten du
          opptrer på, ikke oss.
        </p>

        <h2>Informasjon om oss</h2>
        <p>
          Nettsiden drives foreløpig på privat basis av Jakob Vad Nielsen. Alle henvendelser sendes som e-post til
          kontakt@kwiz.no.
        </p>

        <h2>1. Generelle vilkår</h2>
        <h3>1.1</h3>
        <p>Brukervilkårene gjender for all bruk av nettstedet kwiz.no</p>
        <h3>1.2</h3>
        <p>
          Tjenestene på kwiz.no leveres per i dag av Jakob Vad Nielsen personlig. Dette forholdet kan endre seg senere
          og vil da bli informert om på nettstedet.
        </p>

        <h2>2. Personvern og opplysninger knyttet til deg og din bruk av Nettsiden</h2>
        <p>
          Nettsiden følger gjeldende regler for håndtering av personlige opplysninger i henhold til
          Personvernlovgivingen. Ved å benytte nettsiden samtykker du til en slik behandling og bekrefter at
          personopplysninger du har gitt oss er korrekte. Vennligst les vår Personvernerklæring nøye.
        </p>

        <h2>3. Tilgang til Nettsiden</h2>
        <h3>3.1</h3>
        <p>
          Registrerte brukere vil kunne delta i konkurranser og andre aktiviteter på nettstedet. Kwiz.no forbeholder seg
          retten til, uansett årsak, å kunne trekke tilbake tilgangen til disse tjenestene, uten å varsle deg. Vi vil
          ikke under noen omstendigheter være ansvarlige dersom Nettsiden på et gitt tidspunkt er utilgjengelig for deg.
        </p>
        <h3>3.2</h3>
        <p>
          Som arrangør av en konkurranse stilles det ekstra krav til deg som bruker. Det er under ingen omstendigheter
          lov å publisere innhold som kan oppfattes som krenkende, eller som bryter med loven. Kwiz.no forbeholder seg
          retten til å fjerne innhold vi mener er krenkende eller ulovlig. Ved misbruk vil vi også fjerne din konto og
          ditt innhold uten varsel.
        </p>

        <h2>4. Tillitt til informasjonen som fremkommer</h2>
        <p>
          Nettsiden er i all hovedsak brukerdrevet. Det vil si at det er brukere som legger inn spørsmål og svar i
          konkurranser. Vi gjør vårt ytterste for å sikre at innholdet i konkurranser og på vårt Nettsted er innenfor
          våre og norske lover. Vi frasier oss ansvaret for ethvert direkte, indirekte eller etterfølgende tap som følge
          a bruk av Nettsiden, herunder enhver handling foretatt i tillit til informasjon som har fremkommet. Dette
          gjelder ikke ansvar som i henhold til lovgivningen ikke kan fraskrives.
        </p>

        <h2>6. Regler for bruk og publisering av innhold</h2>
        <h3>6.1</h3>
        <p>
          Du har ikke adgang til benytte innhold fra Nettsiden til kommersiell bruk uten uttrykkelig skriftelig
          tillatelse fra Jakob Vad Nielsen og du plikter å respektere varemerkerettigheter, opphavsrettigheter og andre
          eierskapsbegrensninger og lisenser på Nettsiden.
        </p>
        <h3>6.2</h3>
        <p>
          Det er ikke tillatt å kopiere tekst og bilder fra nettstedet, herunder andre konkurranser, eller på andre
          måter benytte andres tekst og bilder på nettstedet, uten at det foreligger samtykke fra rettighetshaver (jf.
          Åndsverkloven).
        </p>
        <h3>6.3</h3>
        <p>Det er under ingen omstendigheter tillatt å publisere innhold som er krenkende eller bryter med lov.</p>

        <h2>7. Deltaker- og arrangørforpliktelser</h2>
        <p>
          Som deltaker i en konkurranse, og som arrangør av en konkurranse, forplikter du deg til å følge normal
          folkeskikk og ikke publisere innhold som er krenkende eller bryter loven. Kwiz.no forbeholder seg retten til å
          fjerne innhold vi mener bryter med denne regelen, uten varsel til brukeren. Brukere som bryter reglene i
          Brukervilkårene vil kunne bli stengt ute fra tjenesten uten varsel.
        </p>

        <h2>8. Ansvarsfraskrivelse og ansvarsbegrensning</h2>
        <h3>8.1</h3>
        <p>
          Du aksepterer at tilgang til Nettsiden i utgangspunktet tilbys verderlagsfritt. Kwiz.no er ikke ansvarlig
          overfor deg eller andre, for tap eller skade av hvilken som helst art, som følge av uatorisert bruk av
          Nettsiden herunder bruk av informasjonen derfra.
        </p>
        <h3>8.2</h3>
        <p>
          Nettsiden er ment for bruk i Norge. Vi innestår ikke for at tjenester levert på Nettsiden er egnet til bruk,
          eller tilgjengelig, utenfor Norge. De som benytter siden utenfor Norge er selv ansvarlige for overholdelse av
          nasjonale lover i den grad slike kommer til anvendelse.
        </p>
        <h3>8.3</h3>
        <p>
          Ved bruk av Nettsiden, godtar du disse Brukervilkårene og ansvarsbegrensningene som er definert av Kwiz.no. Vi
          forbeholder oss retten til å endre Brukervilkårene og ansvarsbegrensningene og vil publisere en melding på
          Nettsiden når dette skjer.
        </p>
        <h3>8.4</h3>
        <p>
          Kwiz.no gjør sitt ytterste for å sikre at Nettsiden er fullt operasjonell. Internetts natur gjør imidlertid at
          vi kan garantere at Nettsiden er uten forsinkelser, avbrudd eller feil.
        </p>
        <h3>8.5</h3>
        <p>
          Kwiz.no er ikke ansvarlig for avtaler som gjøres mellom konkurransearrangør og deltakere, herunder premiering.
          Tvister knyttet til trekning av vinnere, premieutdeling og annet, er en sak mellom arrangør og deltaker.
        </p>

        <h2>9. Avkall</h2>
        <p>
          Dersom du bryter disse Brukervilkårene og vi ikke foreatar oss noe, vil vi fremdeles ha våre rettigheter og
          rettsmidler i behold for enhver situasjon hvor du misbruker Brukervilkårene.
        </p>

        <h2>10. Generelt</h2>
        <h3>10.1</h3>
        <p>
          Dersom noen av Brukervilkårene ansees å være ugyldige, falt bort eller av annen grunn ikke kan håndheves, skal
          dette vilkåret ansees adskilt fra de øvrige, og skal således ikke påvirke gyldigheten og håndhevingsadgangen
          for de gjenværende vilkårene.
        </p>
        <h3>10.2</h3>
        <p>
          Nettisden tilbys "som den er". Kwiz.no gir ingen garantier eller inneståelser av noe slag, i den utstrekning
          det er tillatt i henhold til lov. Kwiz.no garanterer ikke for nøyaktigheten eller fullstendigheten av
          Nettsiden eller informasjon eller innhold på denne, herunder at den er fri for feil og virus.
        </p>
      </div>
    </Paper>
  )
}

export default withStyles(styles, { withTheme: true })(TermOfUse)
