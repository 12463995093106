import * as React from 'react'

import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import { useDispatch } from 'react-redux'
import { clearGame } from '../../core/store/game'
import { useParams } from 'react-router-dom'

interface GameStarterProps {
  classes: any
}

const GameStarter: React.FC<GameStarterProps> = (props) => {
  const dispatch = useDispatch()
  let { id } = useParams()

  React.useEffect(() => {
    dispatch(clearGame(id))
  })

  return <div></div>
}

const styles = (theme: Theme) => createStyles({})

export default withStyles(styles, { withTheme: true })(GameStarter)
