import { createReducer, createAsyncThunk } from '@reduxjs/toolkit'
import { doSignIn, doSignOut } from '../firebase/auth'
import { push } from 'connected-react-router'

export const requestLogin = createAsyncThunk('auth/login', async (page: string, thunkAPI) => {
  await doSignIn()
  thunkAPI.dispatch(push(page))
  return true
})

export const requestLogout = createAsyncThunk('auth/logout', async (page: string, thunkAPI) => {
  try {
    await doSignOut()
  } catch (err) {
    console.log('Could not log out')
  }
  thunkAPI.dispatch(push('/'))
  return true
})

export const reducer = createReducer(
  { state: 'init', path: undefined },
  {
    [requestLogin.pending.type]: (state: any, action: any) => {
      state.state = 'logging_in'
    },
    [requestLogin.fulfilled.type]: (state: any, action: any) => {
      state.state = 'logged_in'
    },
    [requestLogin.rejected.type]: (state: any, action: any) => {
      state.state = 'login_error'
    },
    [requestLogout.fulfilled.type]: (state: any, action: any) => {
      state.state = 'logged_out'
    },
    '@@reactReduxFirebase/LOGIN': (state: any, action: any) => {
      state.state = 'logged_in'
    },
    '@@reactReduxFirebase/AUTH_EMPTY_CHANGE': (state: any, action: any) => {
      state.state = 'logged_out'
    }
  }
)

export interface AuthState {
  state: 'init' | 'logged_in' | 'logging_in' | 'logged_out' | 'login_error'
  path: string | undefined
}
