import { createAction, createReducer, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios, { AxiosResponse, AxiosError } from 'axios'
import { push } from 'connected-react-router'

export let apiUrl = '/api/'
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  apiUrl = 'http://localhost:5001/kwiz-4ac58/us-central1/api/'
}

export const initialState = {
  games: {}
} as GamesState

export const initialGameState = (kwiz: any) => {
  return {
    state: 'init',
    kwiz: kwiz,
    nickname: undefined,
    activeQuestion: undefined,
    answers: {} as Answers
  }
}

// ACTIONS

export const createTokenObj = (thunkAPI: any) => {
  const state: any = thunkAPI.getState()
  return {
    headers: {
      Authorization: 'Bearer ' + state.firebase.auth.stsTokenManager.accessToken
    }
  }
}

export const initGame = createAction<string, 'game/init'>('game/init')

export const loadGame = createAsyncThunk('game/load', async (id: string, thunkAPI) => {
  try {
    const response: AxiosResponse<any> = await axios.get(apiUrl + 'game/' + id, createTokenObj(thunkAPI))
    return response.data
  } catch (err) {
    let error: AxiosError<HttpProblem> = err
    if (!error.response) {
      throw thunkAPI.rejectWithValue({
        type: 'local',
        title: error.message,
        status: -1
      })
    }
    return thunkAPI.rejectWithValue(error.response.data)
  }
})

export interface GameStartInput {
  kwizId: string
  nickname: string
}

export const startGame = createAsyncThunk('game/start', async (input: GameStartInput, thunkAPI) => {
  try {
    const response: AxiosResponse<any> = await axios.post(
      apiUrl + 'game/' + input.kwizId + '/start',
      {
        nickname: input.nickname
      },
      createTokenObj(thunkAPI)
    )
    return response.data
  } catch (err) {
    let error: AxiosError<HttpProblem> = err
    if (!error.response) {
      throw err
    }
    return thunkAPI.rejectWithValue(error.response.data)
  }
})

export interface AnswerPayload {
  questionId: string
  answerId: string
  kwizId: string
}
export const answerQuestion = createAsyncThunk('game/answer', async (input: AnswerPayload, thunkAPI) => {
  try {
    const url = apiUrl + 'game/' + input.kwizId + '/answer'
    await axios.post(
      url,
      {
        questionId: input.questionId,
        answerId: input.answerId
      },
      createTokenObj(thunkAPI)
    )
    return input
  } catch (err) {
    let error: AxiosError<HttpProblem> = err
    if (!error.response) {
      throw err
    }
    return thunkAPI.rejectWithValue(error.response.data)
  }
})

export const clearGame = createAsyncThunk('game/clear', async (kwizId: string, thunkAPI) => {
  thunkAPI.dispatch(push('/spill/' + kwizId))
})

// REDUCER
export const reducer = createReducer(initialState, {
  [initGame.type]: (state: any, action: PayloadAction<any>) => {
    state.games[action.payload] = initialGameState(action.payload)
  },

  [loadGame.pending.type]: (state: any, action: any) => {
    state.games[action.meta.arg].state = 'loading'
  },
  [loadGame.fulfilled.type]: (state: any, action: any) => {
    let nextState = 'welcome'
    if (action.payload.kwiz.questions.length === Object.keys(action.payload.answers).length) {
      nextState = 'already_played'
    } else if (Object.keys(action.payload.answers).length > 0 || action.payload.nickname !== undefined) {
      nextState = 'running'
    }
    state.games[action.payload.kwiz.id] = {
      state: nextState,
      kwiz: action.payload.kwiz,
      answers: action.payload.answers,
      nickname: action.payload.nickname,
      activeQuestion:
        nextState === 'welcome' || nextState === 'running'
          ? setActiveQuestion(action.payload.kwiz, action.payload.answers)
          : undefined
    } as GameState
  },
  [loadGame.rejected.type]: (state: any, action: any) => {
    let s: GameType = 'error'
    if (action.payload && action.payload.details && action.payload.details.code) {
      if (action.payload.details.code === 'GAME_UNPUBLISHED') {
        s = 'not_started'
      }
    }
    state.games[action.meta.arg] = {
      state: s,
      kwiz: undefined,
      answers: {},
      activeQuestion: undefined
    }
  },
  [clearGame.pending.type]: (state: any, action: any) => {
    delete state.games[action.meta.arg]
  },
  [startGame.pending.type]: (state: any, action: any) => {
    state.games[action.meta.arg.kwizId].state = 'validating'
  },
  [startGame.fulfilled.type]: (state: any, action: any) => {
    state.games[action.meta.arg.kwizId].state = 'running'
    state.games[action.meta.arg.kwizId].nickname = action.nickname
  },
  [startGame.rejected.type]: (state: any, action: any) => {
    state.games[action.meta.arg.kwizId].state = 'validating_error'
  },
  [answerQuestion.fulfilled.type]: (state: any, action: PayloadAction<AnswerPayload>) => {
    state.games[action.payload.kwizId].answers[action.payload.questionId] = action.payload.answerId
    state.games[action.payload.kwizId].activeQuestion = setActiveQuestion(
      state.games[action.payload.kwizId].kwiz,
      state.games[action.payload.kwizId].answers
    )
    if (
      state.games[action.payload.kwizId].kwiz.questions.length ===
      Object.keys(state.games[action.payload.kwizId].answers).length
    ) {
      state.games[action.payload.kwizId].state = 'ended'
    }
  },
  [answerQuestion.rejected.type]: (state, action: any) => {
    if (action.payload && action.payload.details && action.payload.details.code) {
      if (action.payload.details.code === 'ALREADY_ANSWERED') {
      }
    }
  }
})

const setActiveQuestion: any = (kwiz: any, answers: any) => {
  for (let q of kwiz.questions) {
    if (answers === undefined || answers === null) {
      return kwiz.questions[0]
    } else if (answers[q.id] === undefined) {
      return q
    }
  }
  return kwiz.questions[0]
}

export interface Game {
  id: string
}

export interface GamePayload {
  questionId: string
  answerId: string
  userToken: string
  kwizId: string
}

export interface Answers {
  [index: string]: string
}

type GameType =
  | 'init'
  | 'loading'
  | 'welcome'
  | 'validating'
  | 'validating_error'
  | 'running'
  | 'ended'
  | 'already_played'
  | 'error'
  | 'not_started'

export interface GameState {
  state: GameType
  kwiz: any | undefined
  nickname: string | undefined
  activeQuestion: any | undefined
  answers: Answers
}

export interface Games {
  [key: string]: GameState
}

export interface GamesState {
  games: Games
}

export interface HttpProblem {
  type: string
  title: string
  status: number
  details?: unknown
}
