import * as React from 'react'

import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import { Button, Typography, Link } from '@material-ui/core'
import { bake_cookie, read_cookie } from 'sfcookies'
import { useHistory, useLocation } from 'react-router-dom'

const cookieKey = 'kwizConsent'

interface CookieConsentProps {
  classes: any
}

const CookieConsent: React.FC<CookieConsentProps> = (props) => {
  const { classes } = props
  const [viewConsent, setViewConsent] = React.useState(false)
  const history = useHistory()
  const location = useLocation()

  React.useEffect(() => {
    const consent = read_cookie(cookieKey)
    if ((!consent || consent !== 'accepted') && location.pathname !== '/personvern') {
      setViewConsent(true)
    } else {
      setViewConsent(false)
    }
  }, [location.pathname])

  const accept = () => {
    setViewConsent(false)
    bake_cookie(cookieKey, 'accepted')
  }

  if (!viewConsent) {
    return null
  }

  return (
    <div className={classes.root}>
      <div className={classes.consent}>
        <h1>Personvern og cookies</h1>
        <p>
          <Typography variant="body1">
            Kwiz.no er et privat prosjekt, men vi er ansvarlig for dine data. Vi bruker cookies og dine data for at du
            skal kunne benytte tjenestene du ser og bruker.
          </Typography>
        </p>
        <div className={classes.buttons}>
          <div className={classes.buttonsLinks}>
            <Link onClick={() => history.push('/personvern')}>Les mer</Link>
          </div>
          <div className={classes.button}>
            <Button className={classes.button} onClick={accept}>
              Ok - Jeg forstår
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      bottom: 60,
      zIndex: 100000,
      position: 'fixed',
      height: '200px',
      width: '100vw',
    },
    consent: {
      background: 'rgba(230,230,230, 0.9)',
      borderRadius: '5px',
      boxSizing: 'border-box',
      maxWidth: '800px',
      padding: '20px',
      margin: '40px auto',
      boxShadow: '0 0 10px rgba(0,0,0,.25)',
      '& h1': {
        margin: 0,
      },
    },
    buttons: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    buttonsLinks: {
      flex: 1,
      '& a': {
        color: '#000',
        textDecoration: 'underline',
        fontSize: '16px',
        fontWeight: 'bold',
      },
    },
    button: {},
  })

export default withStyles(styles, { withTheme: true })(CookieConsent)
