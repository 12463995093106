import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/analytics'
import 'firebase/database'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyADmzO6jLxJ4n0j8jijB1Ha7sswKkqP6sw',
  authDomain: 'kwiz-4ac58.firebaseapp.com',
  databaseURL: 'https://kwiz-4ac58.firebaseio.com',
  projectId: 'kwiz-4ac58',
  storageBucket: 'kwiz-4ac58.appspot.com',
  messagingSenderId: '914961314675',
  appId: '1:914961314675:web:f5eda8a9dadf1fa7054b06',
  measurementId: 'G-5DQVWXWH1S'
}

export const rfConfig = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableClaims: true
  //enableLogging: true
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()
firebase.firestore()

export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider
export const FacebookAuthProvider = firebase.auth.FacebookAuthProvider
export const auth = firebase.auth()
export const app = firebase
