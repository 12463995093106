import * as React from 'react'

import { ThemeProvider, CssBaseline, Container } from '@material-ui/core'
import Routes from './Routes'
import AppAppBar from './components/AppAppBar'
import { ui } from '@kwiz/core'
import { isLoaded } from 'react-redux-firebase'
import { useSelector } from 'react-redux'
import { ApplicationState } from '../core/store'
import Footer from './components/Footer'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import CookieConsent from './components/CookieConsent'
import { useLocation } from 'react-router-dom'

interface AppProps {
  classes: any
}

const App: React.FC<AppProps> = ({ classes }) => {
  const auth = useSelector((state: ApplicationState) => state.firebase!.auth)
  let mainRef: HTMLDivElement | null = null
  const location = useLocation()
  const [pathState, setPathState] = React.useState('')

  React.useEffect(() => {
    if (pathState !== location.pathname) {
      setPathState(location.pathname)
      if (mainRef !== null) {
        mainRef.scrollTo(0, 0)
      }
    }
  }, [location.pathname, mainRef, pathState])

  return (
    <>
      {!isLoaded(auth) ? (
        <span></span>
      ) : (
        <ThemeProvider theme={ui.theme}>
          <CssBaseline />
          <div className={classes.super}>
            <CookieConsent />
            <div ref={(ref) => (mainRef = ref)} className={classes.overflower}>
              <AppAppBar />
              <main className={classes.main}>
                <Container className={classes.mainContainer}>
                  <div className={classes.appBarSpacer} />
                  <Routes />
                </Container>
              </main>
              <Footer />
            </div>
          </div>
        </ThemeProvider>
      )}
    </>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    super: {
      background: 'linear-gradient(45deg, #ff3366 20%, #FF8E53 80%)',
      '& a': {
        cursor: 'pointer',
      },
    },
    overflower: {
      height: '100vh',
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
      overflow: 'auto',
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        width: '10px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#ff3366',
      },

      '@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape)': {
        '.fullheight': {
          height: '768px',
        },
      },
      '@media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait)': {
        '.fullheight': {
          height: '1024px',
        },
      },
      '@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2)': {
        '.fullheight': {
          height: '320px',
        },
      },
      '@media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2)': {
        '.fullheight': {
          height: '568px',
        },
      },
      '@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2)': {
        '.fullheight': {
          height: '320px',
        },
      },
      '@media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2)': {
        '.fullheight': {
          height: '480px',
        },
      },
    },
    appBarSpacer: {
      height: theme.spacing(3),
    },
    mainContainer: {
      height: '100%',
    },
  })

export default withStyles(styles, { withTheme: true })(App)
