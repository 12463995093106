import * as React from 'react'

import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import { GameState, answerQuestion } from '../../core/store/game'
import { Button } from '@material-ui/core'
import { Dispatch } from 'redux'

interface GameProps {
  classes: any
  game: GameState
  dispatch: Dispatch<any>
  token: string
}

const Game: React.FC<GameProps> = (props) => {
  const { game, dispatch, classes } = props

  const renderAnswers = (answers: any, qId: string) => {
    return answers.map((answer: any) => {
      return (
        <Button
          className={classes.button}
          key={answer.id}
          fullWidth
          onClick={() => dispatch(answerQuestion({ answerId: answer.id, questionId: qId, kwizId: game.kwiz.id }))}
        >
          {answer.text}
        </Button>
      )
    })
  }

  return (
    <div>
      <h1>{game.activeQuestion.title}</h1>
      {renderAnswers(game.activeQuestion.answers, game.activeQuestion.id)}
    </div>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    button: {
      marginBottom: '10px',
    },
  })

export default withStyles(styles, { withTheme: true })(Game)
