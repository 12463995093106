import * as React from 'react'

import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import { Paper } from '@material-ui/core'

interface PrivacyPolicyProps {
  classes: any
}

const PrivacyPolicy: React.FC<PrivacyPolicyProps> = (props) => {
  const { classes } = props

  return (
    <Paper>
      <div className={classes.root}>
        <h1>Kwiz.no personvernerklæring</h1>
        <h2>Innledning</h2>
        <p>
          Kwiz.no er en nettside for quiz og konkurranser. Vi er ansvarlig for all behandling av personopplysninger vi
          samler inn. I denne personvernerklæringen kan du lese mer om hva vi er behandlingsansvarlig for og hva vi
          bruker dine data til.
        </p>
        <p>
          Dersom du har spørsmål om denne personvernerklæringen eller vurdingene vi har gjort, kan du kontakt oss på{' '}
          <a href="mailto:kontakt@kwiz.no">kontakt@kwiz.no</a>
        </p>

        <h2>Når samler Kwiz.no inn personopplysninger?</h2>
        <p>Vi behandler i hovedsak personopplysinger om deg i følgende tilfeller:</p>
        <ul>
          <li>Du har deltatt i en konkurranse</li>
          <li>Du har registrert en arrangørprofil hos oss</li>
          <li>Du har bedt om innsyn i personopplysninger vi har om deg</li>
          <li>Du har sendt oss en henvendelse eller klage</li>
          <li>Du har meldt deg på et nyhetsbrev</li>
          <li>Du besøker vår hjemmeside www.kwiz.no</li>
        </ul>

        <h2>Besøk på kwiz.no</h2>
        <h3>Cookies</h3>
        <p>
          Informasjonskapsler er små tekstfiler som plasseres på din datamaskin når du laster ned en nettside. Noen av
          informasjonskapslene er nødvendige for at ulike tjenester på nettsiden vår skal fungere. Kwiz.no vil dessuten
          i fremtiden bruke informasjonskapsler for å analysere bruken av våre nettsider. Kwiz.no er bygget på tjenester
          fra Google Firebase-plattformen. Du kan lese mer om hvordan de ivaretar dine interesser rundt datahåndtering
          og sikkerhet <a href="https://firebase.google.com/terms/data-processing-terms">her</a>. Bruk av cookies er
          regulert i{' '}
          <a href="https://lovdata.no/dokument/NL/lov/2003-07-04-83/KAPITTEL_2#%C2%A72-7b">ekomloven § 2-7b</a>, og både
          Google og Kwiz.no overholder disse reglene. Her kan du lese mer om{' '}
          <a href="https://policies.google.com/technologies/partner-sites?hl=no">
            hvordan Google bruker informasjon om bruk av nettstedet vårt
          </a>{' '}
          og hvor de jobber for å overholde gjeldende lover om personopplysninger, for eksempel{' '}
          <a href="https://privacy.google.com/businesses/compliance/">GDPR - Google Compliance</a>. Gjennom Google
          Firebase-plattformen vil vi også innhente personopplysninger (navn, e-post, profilbilde og evt. telefonnumer)
          fra Facebook når du logger inn.
        </p>

        <h3>Deltakelse i konkurranser</h3>
        <p>
          Ved deltakelse i konkurranser vil dine svar bli lagret hos oss inntil konkurransen er avsluttet. I denne
          perioden vil svar du har gitt være knyttet til deg. I tillegg vil man i enkelte konkurranser bli bedt om
          telefonnummer, slik at man kan bli kontaktet av arrangøren. Disse opplysningene vil bli slettet så snart
          konkurransen er avsluttet, og senest 10 dager etter at opplysningene er gitt.
        </p>

        <p>
          Kwiz.no vil senere implementere støtte for en konkurranseavgift via Vipps. Personvernerklæringen vil da bli
          oppdatert i henhold til gjeldende regler.
        </p>

        <h3>Arrangering av konkurranser</h3>
        <p>
          Ved arrangering av egne konkurranser på kwiz.no vil vi kreve registrering av telefonnummer og adresse. Disse
          opplysningene vil bli lagret i din profil inntil du velger å avslutte din konto.
        </p>

        <h3>Webanalyse</h3>
        <p>
          For øyeblikket bruker vi ikke cookies til å gjennomføre webanalyse. Vi vil trolig begynne med å bruke cookies
          til webanalyse om kort tid, og da vil personvernerklæringen oppdateres. Bruk av cookies til webanalyse vil
          være samtykkebasert.
        </p>

        <h3>Statistikk fra webserver</h3>
        <p>
          Kwiz.no benytter logger fra vår webserver for å generere statistikk over besøk på kwiz.no. Denne statistikken
          gjør at vi kan forbedre informasjonen på nettsiden vår. Informasjonen inneholder forskjellige variabler, som
          hvilken side du har besøkt, hvilke konkurranser du har deltatt i, dato og tidspunkt for besøk samt teknisk
          informasjon om enheten din. Webserveren sletter umiddelbart IP-adressene til besøkende. I praksis vil det ikke
          være mulig å identifisere de besøkende fra de resterende parameterne. Derfor er statistikken anonym. Denne
          informasjonen kan derfor ikke knyttes til deg som enkeltperson. Behandlingsgrunnlaget for generering av anonym
          statistikk er personvernforordningen artikkel 6 nr. 1 bokstav f, som tillater oss å behandle opplysninger som
          er nødvendig for å ivareta en berettiget interesse som veier tyngre enn hensynet til den enkeltes personvern.
          Den berettigede interessen er å forbedre og videreutvikle informasjon på våre nettsider.
        </p>

        <h3>Tilbakemeldingsfunksjoner</h3>
        <p>
          I enkelte konkurranser vil vi kunne be deg om tilbakemelding på hva du synes om selve konkurransen og den
          tekniske løsningen. Denne informasjonen vil kunne bli lest av konkurransearrangøren og vi som jobber med
          kwiz.no. Opplysningene vil være anonyme og ikke kunne spores tilbake til deg som bruker.
        </p>

        <h2>Hvor lenge holder vi dataene dine?</h2>
        <p>
          Personopplysninger gitt i forbindelse med en konkurranse vil bli slettet så snart konkurransen er merket som
          avsluttet, og senest 10 dager etter at registrerte opplysningene.
        </p>
        <p>
          Data knyttet til din konto (Navn, epost, profilbilde og evt oppgitt telefonnummer) vil beholdes inntil du
          eksplisitt ber om å få slettet kontoen.
        </p>

        <h2>Innsynsrett</h2>
        <p>
          Alle personopplysninger vi lagrer kan du be om innsyn i og kreve fjerning av. Dette vil ikke gjelde data vi må
          lagre for juridiske eller sikkerhetsmessige formål (ved misbruk av tjenestene).
        </p>

        <h2>Dine rettigheter</h2>
        <p>
          Du kan utøve dine rettigheter ved å sende en e-post til <a href="mailto:kontakt@kwiz.no">kontakt@kwiz.no</a>.
          Vi vil besvare alle henvendelser.
        </p>
      </div>
    </Paper>
  )
}

export const styles = (theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '900px',
      padding: '30px',
      margin: 'auto',
      ...theme.typography.body1,
      '& h1': {
        ...theme.typography.h1,
        marginBottom: '1em',
        fontSize: '3em',
        [theme.breakpoints.down('sm')]: {
          fontSize: '2em',
        },
      },
      '& h2': {
        ...theme.typography.h2,
        marginBottom: '1em',
        fontSize: '2em',
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.8em',
        },
      },
      '& h3': {
        ...theme.typography.h4,
        marginBottom: '0.2em',
        fontSize: '1.2em',
        [theme.breakpoints.down('sm')]: {
          fontSize: '1.3em',
        },
      },
      '& h4': {
        ...theme.typography.h4,
        marginBottom: '1em',
      },
      '& h5': {
        ...theme.typography.h5,
        marginBottom: '1em',
      },
      '& h6': {
        ...theme.typography.h6,
        marginBottom: '1em',
      },
      '& p': {
        ...theme.typography.body1,
        marginTop: '0.1em',
      },
      '& a': {
        textDecoration: 'none',
        color: 'FF5700 !important',
        '&:hover': {
          color: 'FF5700 !important',
          textDecoration: 'underline',
        },
        '&:active': {
          color: 'FF5700 !important',
        },
        '&:link': {
          color: 'FF5700 !important',
        },
        '&:visited': {
          color: 'FF5700 !important',
        },
      },
    },
  })

export default withStyles(styles, { withTheme: true })(PrivacyPolicy)
