import * as React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import { styles } from './PrivacyPolicy'
import { Paper } from '@material-ui/core'

interface HelpProps {
  classes: any
}

const Help: React.FC<HelpProps> = (props) => {
  const { classes } = props

  return (
    <Paper>
      <div className={classes.root}>
        <h1>Slik fungerer kwiz.no</h1>

        <h2>Først litt om hva kwiz.no er</h2>
        <p>
          Kwiz.no er en nyutviklet plattform for arrangering av quiz, der man enkelt kan få inn deltakeres svar på en
          enkel og elegant måte. Om selve quiz'en arrangeres over en livestream på Internett, eller på en lokal pub
          spiller ingen rolle for oss. Vi er i en tidlig fase der vi kjører ulike pilotprosjekter for å se hva som
          fungerer best. Vi håper derfor at du har litt tålmodighet med oss og gir oss en sjanse til å få alle ideer og
          løsninger på plass.
        </p>
        <h2>Slik spiller du</h2>
        <p>
          Når du deltar på en quiz (eller Kwiz som vi kaller det) vil arrangøren oppgi en kwiz-kode. For å delta på
          kwizzen går du inn på https://kwiz.no i en nettleser på din mobil, pc eller nettbrett. Her finner du et skjema
          på forsiden der du skriver inn koden du har fått oppgitt.
        </p>
        <p>
          Dersom du ikke har deltatt på en Kwiz i det siste vil du bli bedt om å logge inn med din Facebook-bruker.
          Dette gjør vi for å forhindre misbruk av tjenesten og for at arrangøren av en Kwiz skal kunne komme i kontakt
          med deg, gitt at du f.eks. er så heldig å vinne noe :)
        </p>
        <p>
          Så snart du har logget inn vil vi be deg om å oppgi et kallenavn. Det er dette navnet som vil bli vist til
          alle på resultattavler. Du velger selv om du vil bruke ditt eget navn eller finne på et mer anonymt navn. Når
          du har valgt et navn klikker du på start-knappen for å komme i gang med spillet.
        </p>
        <p>
          Når spillet er i gang får du opp ett og ett spørsmål med 4 svaralternativer. Klikk på svaret du tror er
          riktig. Du sendes da til neste spørsmål, helt til du er ferdig.
        </p>
        <p>
          I denne tidlige utviklingsfasen av Kwiz.no har vi ikke støtte for å gi deg resultater eller melding om gevinst
          på selve siden. Hvem som vinner og riktige svar vil bli formidlet av arrangøren av kwizzen du deltar på.
        </p>
        <h2>Innspill og ideer</h2>
        <p>
          Vi er ekstremt lydhøre for tilbakemeldinger og ideer du måtte ha til denne tjenesten. Send oss en epost
          (kontakt@kwiz.no) og så lover vi å besvare den så fort vi kan.
        </p>
        <h2>Ting vi jobber med</h2>
        <p>
          Som sagt er vi ikke helt ferdig med kwiz.no. Og vi jobber på spreng for å få alle ting på plass. Det som
          kommer snart er
        </p>
        <ul>
          <li>
            Profilside. Du får veldig snart mulighet til å endre din egen profil og få innsyn i hvilke data vi har om
            deg. Samt mulighet til å slette din konto og alle personopplysninger. Dette har høy prioritet hos oss.
          </li>
          <li>
            Spillstatus. Vi vet det er kjedelig å ikke få status om resultater av en kwiz. Vi jobber med flere ulike
            ting for å gi bedre status underveis (rett og galt svar), men også en oversikt over hvordan man klarte seg
            når kwizzen er avsluttet. Arrangøren har allerede en slik oversikt over det samlede resultatet. Vi vil at du
            skal få se dine resultater på samme måten.
          </li>
          <li>
            Lage egne kwiz. Vi vil at alle skal kunne bruke denne plattformen til å kunne arrangere egne kwiz. Om ikke
            lenge vil vi åpne for at alle skal få tilgang til det vi kaller Kwiz Studio. Følg med...
          </li>
        </ul>
        <p>
          <br />
          <br />
          <strong>Kwiz-teamet</strong>
        </p>
      </div>
    </Paper>
  )
}

export default withStyles(styles, { withTheme: true })(Help)
