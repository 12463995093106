import * as React from 'react'

import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import { Container, Button, Typography, FormControl, Input } from '@material-ui/core'
import { useSelector, useDispatch } from 'react-redux'
import { ApplicationState } from '../../core/store'
import { useParams, useHistory } from 'react-router-dom'
import { useEffect } from 'react'
import { initGame, loadGame, startGame } from '../../core/store/game'
import Game from './Game'

interface GameControllerProps {
  classes: any
}

const GameController: React.FC<GameControllerProps> = (props) => {
  const { classes } = props
  let { id } = useParams()
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useSelector((state: ApplicationState) => state.firebase!.auth)
  const state = useSelector((state: ApplicationState) => state)

  useEffect(() => {
    let mounted = true
    if (mounted) {
      if (!state.games.games[id]) {
        dispatch(initGame(id))
      } else if (state.games.games[id].state === 'init') {
        dispatch(loadGame(id))
      }
    }
    return () => {
      mounted = false
    }
  }, [dispatch, id, state])

  const FrontPageButton = () => {
    return (
      <Button color="primary" variant="contained" onClick={() => history.push('/')}>
        Tilbake til forsiden
      </Button>
    )
  }

  const Welcome = () => {
    const [nickname, setNickname] = React.useState(auth.displayName || '')

    const handleChange = (event: any) => {
      setNickname(event.target.value)
    }

    const handleSubmit = (event: any) => {
      let n: string = nickname
      event.preventDefault()
      dispatch(
        startGame({
          kwizId: id,
          nickname: n,
        })
      )
    }

    const isValidating: boolean = state.games.games[id].state === 'validating'
    const isValidatingError: boolean = state.games.games[id].state === 'validating_error'

    return (
      <React.Fragment>
        <Typography variant="h4" align="center" gutterBottom>
          Velg ditt navn i kwizzen
        </Typography>
        <form className={classes.nickForm} noValidate autoComplete="off" onSubmit={handleSubmit}>
          <FormControl>
            <Input
              className={classes.nickField}
              id="nickName"
              autoFocus
              onChange={handleChange}
              placeholder={auth.displayName || 'Velg et kallenavn'}
            />
          </FormControl>

          <br />
          <Button
            color="primary"
            variant="contained"
            type="submit"
            fullWidth
            className={classes.nickButton}
            disabled={isValidating}
          >
            Start spillet
          </Button>
        </form>
        {isValidatingError && (
          <div className={classes.error}>Du kan dessverre ikke bruke dette kallenavnet. Velg et annet.</div>
        )}
        <div className={classes.welcomeInfo}>
          <Typography variant="body1" align="left" color="textSecondary" gutterBottom>
            Før vi starter trenger vi at du skriver inn et kallenavn. Det er dette navnet som vil komme opp i
            resultatlistene. Det kan enten være ditt eget navn eller noe annet dersom du vil være anonym. Vi ber om at
            du viser vanlig folkeskikk og ikke bruker obskøne og fornærmende ord og uttrykk.
          </Typography>
        </div>
      </React.Fragment>
    )
  }

  const Ended = () => {
    return (
      <React.Fragment>
        <Typography variant="h4" align="center" gutterBottom>
          Tusen takk for spillet!
        </Typography>
        <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
          Dine svar er tryg lagret hos oss. Følg med på live-sendingen for å se om du vant.
        </Typography>
        <FrontPageButton />
      </React.Fragment>
    )
  }

  const AlreadyPlayed = () => {
    return (
      <React.Fragment>
        <Typography variant="h4" align="center" gutterBottom>
          Du har allerede fullført denne kwizzen!
        </Typography>
        <FrontPageButton />
      </React.Fragment>
    )
  }

  const NotStarted = () => {
    return (
      <React.Fragment>
        <Typography variant="h4" align="center" gutterBottom>
          Vi er ikke klar helt ennå...
        </Typography>
        <Typography variant="body1" align="center" color="textSecondary" gutterBottom>
          Så flott at du vil spille med oss. Spillet har ikke startet ennå. Kom tilbake senere.
        </Typography>
        <FrontPageButton />
      </React.Fragment>
    )
  }

  const Error = () => {
    return (
      <div>
        <h1>Hmm, det har skjedd en feil.</h1>
      </div>
    )
  }

  const Validating = () => {
    return (
      <div>
        <h1>Sjekker kallenavn...</h1>
      </div>
    )
  }

  const render = () => {
    if (!state.games.games[id] || state.games.games[id].state === 'loading' || state.games.games[id].state === 'init') {
      return <h1>Laster kwizzen...</h1>
    } else if (state.games.games[id].state === 'welcome' || state.games.games[id].state === 'validating_error') {
      return <Welcome />
    } else if (state.games.games[id].state === 'validating') {
      return <Validating />
    } else if (state.games.games[id].state === 'ended') {
      return <Ended />
    } else if (state.games.games[id].state === 'already_played') {
      return <AlreadyPlayed />
    } else if (state.games.games[id].state === 'error') {
      return <Error />
    } else if (state.games.games[id].state === 'not_started') {
      return <NotStarted />
    } else {
      return (
        <Game
          game={state.games.games[id]}
          dispatch={dispatch}
          token={state.firebase.auth.stsTokenManager.accessToken}
        />
      )
    }
  }

  return (
    <Container className={classes.root}>
      <div className={classes.gameDiv}>{render()}</div>
    </Container>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      height: '100%',
      paddingBottom: '90px',
    },
    gameDiv: {
      textAlign: 'center',
    },
    nickForm: {
      margin: 'auto',
      marginTop: '20px',
      maxWidth: '400px',
    },
    nickField: {
      backgroundColor: '#fff',
      fontSize: '1.7rem',
      padding: '8px',
      textAlign: 'center',
    },
    nickButton: {
      marginTop: '20px',
    },
    error: {
      marginTop: '20px',
      color: 'red',
    },
    welcomeInfo: {
      margin: '30px',
    },
  })

export default withStyles(styles, { withTheme: true })(GameController)
