import { Button, FormControl, Input, Link } from '@material-ui/core'
import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import axios, { AxiosResponse } from 'axios'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { apiUrl } from '../../core/store/game'

interface JoinBoxProps {
  classes: any
}

export interface I18nHash {
  [details: string]: string
}
const i18n: I18nHash = {
  GAME_NOT_FOUND: 'Det finnes ingen kwiz med denne koden',
  GAME_ENDED: 'Denne kwizzen er avsluttet',
  GAME_UNPUBLISHED: 'Denne kwizzen har ikke startet ennå',
}

const JoinBox: React.FC<JoinBoxProps> = (props) => {
  const { classes } = props
  const history = useHistory()
  const dispatch = useDispatch()
  const [formState, setFormState] = React.useState({ value: '', state: 'IDLE', message: '' })

  const handleSubmit = async (event: any) => {
    event.preventDefault()
    try {
      const response: AxiosResponse<any> = await axios.get(apiUrl + 'game/' + formState.value + '/check')
      if (response.data && response.data.state === 'OK') {
        setFormState({ value: '', state: 'IDLE', message: '' })
        dispatch(history.push('/spill/' + formState.value + '/start'))
      } else if (response.data) {
        setFormState({ ...formState, state: response.data.state, message: i18n[response.data.state] })
      }
    } catch (err) {
      setFormState({ ...formState, state: 'ERROR', message: 'En feil oppstod. Prøv igjen!' })
    }
  }

  const handleChange = (event: any) => {
    setFormState({ ...formState, value: event.target.value })
  }

  const renderAlert = () => {
    return (
      <div className={classes.alert}>
        {formState.state === 'IDLE' || formState.state === 'OK' ? '' : formState.message}
      </div>
    )
  }

  return (
    <div>
      <div className={classes.join}>
        {renderAlert()}
        <form onSubmit={handleSubmit}>
          <FormControl className={classes.field}>
            <Input
              className={classes.field}
              id="gameid-front"
              placeholder="KWIZ-KODE"
              autoFocus
              disableUnderline
              inputProps={{ min: 0, style: { textAlign: 'center' } }}
              onChange={handleChange}
            />
          </FormControl>
          <br />
          <Button className={classes.enterButton} color="primary" variant="contained" fullWidth type="submit">
            START
          </Button>
        </form>
      </div>
      <div className={classes.needHelp}>
        <Link className={classes.whiteLink} onClick={() => history.push('/hjelp')}>
          Trenger du hjelp?
        </Link>
      </div>
    </div>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    join: {
      marginTop: '40px',
      maxWidth: '300px',
      margin: 'auto',
      padding: '2px',
    },
    field: {
      backgroundColor: '#fff',
      fontSize: '1.3rem',
      textAlign: 'center',
      marginBottom: '5px',
      width: '100%',
      borderRadius: '4px',
    },
    enterButton: {
      fontSize: '1.3rem',
      paddingTop: 0,
      paddingBottom: 0,
    },
    needHelp: {
      margin: 'auto',
      marginTop: '10px',
      width: '100%',
      textAlign: 'center',
      color: '#fff',
    },
    whiteLink: {
      color: '#fff',
    },
    alert: {
      height: '30px',
      width: '100%',
      textAlign: 'center',
      color: '#fff',
    },
  })

export default withStyles(styles, { withTheme: true })(JoinBox)
