import * as React from 'react'

import { Theme } from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import {
  Typography,
  Link,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core'
import { useHistory } from 'react-router-dom'

interface FooterProps {
  classes: any
}

const Footer: React.FC<FooterProps> = (props) => {
  const { classes } = props
  const [openAbout, setOpenAbout] = React.useState(false)
  const descriptionElementRef = React.useRef<HTMLElement>(null)
  const history = useHistory()

  const handleCloseAbout = () => {
    setOpenAbout(false)
  }

  const personvern = () => {
    setOpenAbout(false)
    history.push('/personvern')
  }
  const AboutDialog = (classes: any) => {
    return (
      <Dialog
        open={openAbout}
        onClose={handleCloseAbout}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">Om kwiz.no</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
            <p>
              kwiz.no er et lite prosjekt i en tidlig oppstartsfase. Ideen er å etablere en Quiz-motor man kan bruke
              under live-streaming på Internett. Sånn som f.eks. en livestream på Facebook eller Twitch. Det er mye
              arbeid som gjenstår før vi er der vi skal være, men allerede nå kjører vi flere tidlige pilotprosjekter
              for å se om vi sammen kan skape en fantastisk plattform for online kwizzing.
            </p>
            <p>
              Tjenesten er drevet av Jakob Vad Nielsen på privat basis og har per nå ingen inntekter. Jakob har over 30
              års erfaring med utvikling og er ansvarlig for at dine svar og data blir håndtert på en trygg og god måte.
              Les mer om dette i vår <Link onClick={personvern}>Personvernerklæring</Link>
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAbout} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    )
  }

  return (
    <footer className={classes.footer}>
      <Typography variant="subtitle1" align="center" component="p">
        <Link className={classes.footerLink} onClick={() => setOpenAbout(true)}>
          om Kwiz.no
        </Link>{' '}
        |{' '}
        <Link className={classes.footerLink} onClick={() => history.push('/personvern')}>
          Personvernerklæring
        </Link>{' '}
        |{' '}
        <Link className={classes.footerLink} onClick={() => history.push('/vilkaar')}>
          Brukervilkår
        </Link>
      </Typography>
      <AboutDialog classes={classes} />
    </footer>
  )
}

const styles = (theme: Theme) =>
  createStyles({
    footer: {
      backgroundColor: 'transparent',
      color: '#fff',
      padding: theme.spacing(4),
      paddingTop: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    footerLink: {
      color: '#fff',
      [theme.breakpoints.down('sm')]: {
        fontSize: '0.8rem',
      },
    },
  })

export default withStyles(styles, { withTheme: true })(Footer)
